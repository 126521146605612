// Bootstrap
$font-family-base: Lato;
$headings-font-family: Oswald;
$font-family-graficas: Montserrat;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 1084px // LCR changed this
// ) !default;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 1024px // LCR changed this
// );

// Los míos
$anaranjado: rgb(255, 152, 15);
$anaranjado-40: rgba(255, 152, 15, 0.4);
$anaranjado-80: rgba(255, 152, 15, 0.8);

$primary: $anaranjado;
