.contactenos {
  position: relative;
  width: 100%;

  &-envoltorio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-fondo {
    background-image: url("/assets/images/disenio-y-construccion.png");
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-top: 768 / 1024 * 100%;
    width: 100%;
  }

  &-caja {
    background-color: $anaranjado-40;
    height: 100%;
    @extend .mx-auto;
    width: 15.125rem;
  }

  &-cuerpo {
    color: white;
    font-weight: 800;
    line-height: 1.25;
    padding-top: 19 / 768 * 100%;
    @extend .text-center;
  }

  &-h1 {
    color: white;
    font-family: $headings-font-family;
    font-size: 2.35rem;
    font-weight: 800;
    letter-spacing: 0.05em;
    padding-top: 56 / 768 * 100%;
    @extend .text-center;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: underline;
  }
}

@include media-breakpoint-up(sm) {
  .contactenos {
    width: map-get($container-max-widths, sm);
  }
}
@include media-breakpoint-up(md) {
  .contactenos {
    width: map-get($container-max-widths, md);
  }
}
@include media-breakpoint-up(lg) {
  .contactenos {
    width: map-get($container-max-widths, lg);
  }
}
