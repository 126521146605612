// Lo siguiente son las medidas del diapositivo o sea,
// en grande ("lg").
$alto: 720px;
$ancho: 960px;
$aspect-ratio-pagina: $ancho / $alto;

// Debido al diseño para pantalla grande, estas funciones convierten las medidas
// del diseño original a unos relativas al tamaño de la letra del tamaño de
// la pantalla.
@function pt-to-rem($points) {
  @return px-to-rem($points * 96px / 72pt);
}

// Debido al diseño para pantalla grande, esta función convierte las medidas
// del diseño original a unos relativas al tamaño de la letra del tamaño de
// la pantalla.
@function px-to-rem($pixels) {
  @return $pixels / 32px * 1rem;
}

@mixin encabezado {
  background-image: url("/assets/images/objeto-slide4-678x120.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: px-to-rem(120px);
  position: relative;
  width: px-to-rem(678px);
}

@mixin encabezado-texto {
  bottom: px-to-rem(-8px);
  color: white;
  font-size: 2.35rem;
  font-weight: 800;
  letter-spacing: px-to-rem(3.75px);
  margin-bottom: 0;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  width: 100%;
}

@mixin imagen-fondo-escalable($imagen) {
  // From: https://love2dev.com/blog/responsive-hero-background-opacity/
  &::before {
    background-image: url($imagen);
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    // Cambié alto y ancho a porcentaje
    height: 100%;
    width: 100%;
    z-index: -2;
    opacity: 0.8;
  }
}

html {
  font-size: 12px;
}

.pagina {
  @extend .mb-sm-2;
  @extend .d-flex;
  @extend .justify-content-center;
  position: relative;
  width: $ancho;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 16px;
  }

  .pagina {
    width: map-get($container-max-widths, sm);
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 24px;
  }

  .pagina {
    width: map-get($container-max-widths, md);
  }
}
@include media-breakpoint-up(lg) {
  html {
    font-size: 32px;
  }

  .pagina {
    height: $alto;
    width: $ancho;
  }
}

$panel-ancho: 912px;

@include media-breakpoint-up(sm) {
  html {
    font-size: 16px;
  }

  .panel {
    width: map-get($container-max-widths, sm) * $panel-ancho / map-get($container-max-widths, lg);
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 24px;
  }

  .panel {
    width: map-get($container-max-widths, md) * $panel-ancho / map-get($container-max-widths, lg);
  }
}
@include media-breakpoint-up(lg) {
  html {
    font-size: 32px;
  }

  .panel {
    width: $panel-ancho;
  }
}
