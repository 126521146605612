.infragua {
  position: relative;
  width: 100%;

  &-envoltorio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-fondo {
    background-image: url("/assets/images/objeto-slide-2-992x285.png");
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-top: 285 / 992 * 100%;
    width: 100%;
  }

  &-caja {
    background-color: $anaranjado-80;
    margin-top: 16%;
    @extend .mx-auto;
    width: 15.125rem;
  }

  &-cuerpo {
    font-size: 18 / 72 * 96rem / 32;
    font-weight: 400;
    line-height: 1.25;
    margin-top: 2rem;
    padding: 0 2.75rem;
    @extend .text-center;
  }

  &-h1 {
    color: white;
    font-family: $headings-font-family;
    font-size: 80.5 / 72 * 96rem / 32;
    font-weight: 800;
    // letter-spacing: 0.05em;
    @extend .text-center;
  }
}

@include media-breakpoint-up(sm) {
  .infragua {
    width: map-get($container-max-widths, sm);
  }
}
@include media-breakpoint-up(md) {
  .infragua {
    width: map-get($container-max-widths, md);
  }
}
@include media-breakpoint-up(lg) {
  .infragua {
    width: map-get($container-max-widths, lg);
  }
}
