$heroe-aspect-ratio: 1110px / 858px;

.heroe {
  position: relative;
  width: 100%;

  &-imagen {
    height: auto;
    max-width: 100%;
    width: auto;

    &-envoltorio {
      max-width: 100%;
      padding: 13% 5% 0 4%;
    }
  }

  &-fondo {
    background-image: url("/assets/images/tl-471x360.jpg");
    background-size: cover;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  &-texto {
    color: white;
    line-height: 105%;
    text-align: center;
    width: 100%;
  }

  &-texto-grande {
    @extend .heroe-texto;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 5% 0 2%;
  }

  &-texto-pequeno {
    @extend .heroe-texto;
    font-size: 1rem;
    font-weight: 400;
    padding: 0 18% 5%;
  }

  &-texto-separador {
    border-top: 0.25em solid white;
    margin: 2% 0;
    width: 7.5em;
    @extend .mx-auto;
  }

  &-texto-fondo {
    background-color: $anaranjado;
    margin: 0 0 0 27%;
    opacity: 0.7;
    width: 53%;
  }
}

@include media-breakpoint-up(sm) {
  .heroe {
    height: map-get($container-max-widths, sm) / $heroe-aspect-ratio;
    max-height: map-get($container-max-widths, sm) / $heroe-aspect-ratio;
    max-width: map-get($container-max-widths, sm);
    width: map-get($container-max-widths, sm);
  }
}
@include media-breakpoint-up(md) {
  .heroe {
    height: map-get($container-max-widths, md) / $heroe-aspect-ratio;
    max-height: map-get($container-max-widths, md) / $heroe-aspect-ratio;
    max-width: map-get($container-max-widths, md);
    width: map-get($container-max-widths, md);
  }
}
@include media-breakpoint-up(lg) {
  .heroe {
    height: map-get($container-max-widths, lg) / $heroe-aspect-ratio;
    max-height: map-get($container-max-widths, lg) / $heroe-aspect-ratio;
    max-width: map-get($container-max-widths, lg);
    width: map-get($container-max-widths, lg);
  }
}
