.nuestro-trabajo {
  h1 {
    bottom: px-to-rem(-8px);
    color: white;
    font-size: 2.35rem;
    font-weight: 800;
    letter-spacing: px-to-rem(3.75px);
    margin-bottom: 0;
    position: absolute;
    text-align: right;
    text-transform: uppercase;
    width: 100%;
  }

  .encabezado {
    background-image: url("/assets/images/objeto-slide4-678x120.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: px-to-rem(120px);
    position: relative;
    width: px-to-rem(678px);
  }

  .tarjeta {
    @extend .align-self-center;
    @extend .col-4;

    .texto {
      font-family: $font-family-graficas;
      font-size: 18 / 72 * 96rem / 32;
      text-align: center;
    }

    img {
      @extend .mx-auto;
      height: auto;
      max-width: 210px;
      object-fit: contain;
      width: 100%;
    }
  }
}
